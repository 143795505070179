* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Varela Round", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  overflow-x: hidden;
  color: #868f9b;
}

h1,
h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 20px;
  color: #10161a;
}

h1 {
  font-size: 54px;
}

h2 {
  font-size: 36px;
}

header {
  position: relative;
}
